import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import components from '../../assets/images/stegvision/components.png';
import dashboard from '../../assets/images/stegvision/dashboard.png';
import Warning from '../../components/Warning';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';
import Caption from '../../components/Caption';
import snkrsalarm from '../../assets/images/freelance/AutoClicker.png';
import xenonpro from '../../assets/images/freelance/XenonPro.png';
import xenon from '../../assets/images/freelance/XenonGraphics.png';


function Freelance() {

    useEffect(() => {
        document.getElementById("works").className = 'text-secondary line-through'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#D2AA5C');
    const [accent, setAccent] = useState('#D2AA5C');
    const [main, setMain] = useState('#D6A158');


    return (
        <div className='bg-[primary] pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center text-secondary'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Freelance</title>
                </Helmet>
                <WorkHeader
                    title='Freelance'
                    bg={bg}
                    accent={accent}
                    timeline='2020 - 2023'
                    role='Designer & Developer'
                    skills='Figma, Adobe, HTML/CSS/Tailwind, Javascript, React'
                    text='#fff'
                />
                <div className='flex flex-col gap-12 w-full items-center my-12'>
                    <ProjectImage src={snkrsalarm} alt='Snkrs Alarm Chrome Extention' style='w-11/12 md:w-3/4 xl:w-1/2' />
                    <Caption text='Auto Clicker Chrome Extension' bg={accent} />
                    <ProjectImage src={xenonpro} alt='Snkrs Alarm Chrome Extention' style='w-11/12 md:w-3/4 xl:w-1/2' />
                    <Caption text='Sneaker Bot UI' bg={accent} />
                    <ProjectImage src={xenon} alt='Snkrs Alarm Chrome Extention' style='w-11/12 md:w-3/4 xl:w-1/2' />
                    <Caption text='Promotional Graphics for Instagram and Twitter' bg={accent} />
                    <Warning title='Want to learn more?'
                        desc='I would love to share the stories behind the work I have done while freelancing. If you would like to hear more, contact me!'
                        link='mailto:stephaniey.zhang@mail.utoronto.ca'
                        button='email me :-)'
                        bg={bg}
                        buttoncolour='#fff' />
                    <PageFlip next='ICUBE UTM' nextLink='/icube' />
                </div>
            </div>
        </div>
    );
}

export default Freelance;